// cherrypick functions
import { sayHi } from "./helpers/test"
import { toggleAccordions } from "./components/accordion"
import "./components/nav"
import "./components/stats"
/* LOAD SCRIPTS */
document.addEventListener("DOMContentLoaded", () => {
	document.body.classList.add("body")

	// TEST
	const test = document.getElementById("test-js-script")
	test && test.addEventListener("click", sayHi)

	// ACCORDION
	const accordionButtons = document.querySelectorAll(".js-accordion-btn")
	if (accordionButtons) {
		toggleAccordions(accordionButtons)
	}
})
