/* eslint-disable no-unused-expressions */
export const slideUp = (target, duration = 500) => {
    const style = target.style
    const accordionLink = target.parentElement.firstElementChild

    accordionLink.classList.remove('opened')
    style.transitionProperty = 'height, margin, padding'
    style.transitionDuration = duration + 'ms'
    style.boxSizing = 'border-box'
    style.height = target.offsetHeight + 'px'
    target.offsetHeight
    style.overflow = 'hidden'
    style.height = 0
    style.paddingTop = 0
    style.paddingBottom = 0
    style.marginTop = 0
    style.marginBottom = 0

    window.setTimeout(() => {
        style.display = 'none'
        style.removeProperty('height')
        style.removeProperty('padding-top')
        style.removeProperty('padding-bottom')
        style.removeProperty('margin-top')
        style.removeProperty('margin-bottom')
        style.removeProperty('overflow')
        style.removeProperty('transition-duration')
        style.removeProperty('transition-property')
    }, duration)
}

export const slideDown = (target, duration = 500) => {
    const style = target.style
    const accordionLink = target.parentElement.firstElementChild

    let displayValue = window.getComputedStyle(target).display

    style.removeProperty('display')
    displayValue === 'none' && (displayValue = 'block')
    style.display = displayValue
    const offset = target.offsetHeight
    style.overflow = 'hidden'
    style.height = 0
    style.paddingTop = 0
    style.paddingBottom = 0
    style.marginTop = 0
    style.marginBottom = 0
    style.boxSizing = 'border-box'
    style.transitionProperty = 'height, margin, padding'
    style.transitionDuration = duration + 'ms'
    target.offsetHeight
    style.height = offset + 'px'
    style.removeProperty('padding-top')
    style.removeProperty('padding-bottom')
    style.removeProperty('margin-top')
    style.removeProperty('margin-bottom')
    window.setTimeout(() => {
        style.removeProperty('height')
        style.removeProperty('overflow')
        style.removeProperty('transition-duration')
        style.removeProperty('transition-property')
        accordionLink.classList.add('opened')
    }, duration)
}

export const slideToggle = (target, duration = 500) => {
    window.getComputedStyle(target).display === 'none'
        ? slideDown(target, duration)
        : slideUp(target, duration)
}

export const slideToggleFineControl = (target, durationUp = 500, durationDown = 500) => {
    window.getComputedStyle(target).display === 'none'
        ? slideDown(target, durationUp)
        : slideUp(target, durationDown)
}

export const toggleAria = (element, att, value) => {
    // If setting a specific value do it and return
    if (value) {
        element.setAttribute(att, value)
        return
    }
    if (element.getAttribute(att) === 'true') {
        element.setAttribute(att, false)
    } else {
        element.setAttribute(att, true)
    }
}
