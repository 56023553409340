import { CountUp } from 'countup.js';

// Function to handle intersection
const handleIntersection = (entry, numElement, numValue, text, observer) => {
	if (entry.isIntersecting && !numElement.incremented) {
		const reduceMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
		if (reduceMotionQuery.matches) {
			numElement.textContent = numValue.toLocaleString('en-GB') + text;
			numElement.incremented = true;
			observer.disconnect();
			return;
		}

		// Create a new CountUp instance with the end value
		let countUp = new CountUp(numElement, numValue)

		// Start the count up
		countUp.start();

		numElement.incremented = true;
		observer.disconnect();
	}
};

// Select all HTML elements that contain the number
const numElements = document.querySelectorAll('.stats-block');

numElements.forEach(numElement => {
	const content = numElement.textContent;
	const index = content.search(/[a-zA-Z]/);
	const numValue = Number(content.slice(0, index));
	const text = content.slice(index);

	const observer = new IntersectionObserver((entries) => {
		entries.forEach(entry => handleIntersection(entry, numElement, numValue, text, observer));
	});

	observer.observe(numElement);
});