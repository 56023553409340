import { trapFocus } from "../helpers/trapFocus"

// Toggle Hamburger Button & Dropdown.
const nav = document.getElementsByClassName("nav")
const hamburgerButton = document.querySelector(".hamburger-button")
const logo = document.querySelector(".logo")
const html = document.documentElement

hamburgerButton.addEventListener("click", function () {
	hamburgerButton.classList.toggle("active")

	if (hamburgerButton.getAttribute("aria-expanded") === "false") {
		this.setAttribute("aria-expanded", "true")
		html.classList.add("menu-is-open")
		trapFocus(nav[0], logo)
	} else {
		this.setAttribute("aria-expanded", "false")
		html.classList.remove("menu-is-open")
	}
})
