import { slideToggle, toggleAria } from "../helpers/toggles"

export function toggleAccordions(buttons) {
	const accordionHead = Array.from(buttons)

	accordionHead.forEach((link) => {
		link.addEventListener("click", () => {
			link.classList.toggle("c-accordion__head--active")
			slideToggle(link.parentNode.nextElementSibling, 200)
			toggleAria(link, "aria-expanded")
		})
	})
}
